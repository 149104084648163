@mixin btn-base() {
	align-items: center;
	display: flex;
	font-family: var(--ff-sans-serif);
	font-size: var(--fs-btn-text);
	font-weight: var(--fw-medium);
	gap: var(--spacing-base);
	line-height: var(--lh-full);
	outline: none;
	width: fit-content;

	&::after {
		content: '';
		height: 12px;
		mask: url('/images/icons/btn-primary-arrow.svg');
		mask-repeat: no-repeat;
		mask-size: 7px 12px;
		min-width: 7px;
		width: 12px;
	}

	&:hover {
		cursor: pointer;
	}
}

@mixin btn-primary() {
	@include btn-base();
	background-color: var(--color-primary-dark);
	border: 1.5px solid var(--color-primary-dark);
	border-radius: var(--br-sm);
	color: var(--color-base);
	padding: calc(var(--spacing-base) * 2) calc(var(--spacing-base) * 3);

	&::after {
		background-color: var(--color-base);
	}

	&:hover {
		box-shadow: var(--btn-shadow);

		//added to overwrite that in main - remove w cleanup
		background-color: var(--color-primary-dark);
		border: 1.5px solid var(--color-primary-dark);
		color: var(--color-base);
	}

	&:active,
	&:focus,
	&:focus-visible {
		background-color: var(--color-active-primary);
		border: 1.5px solid var(--color-active-primary);
		box-shadow: none;
	}
}

@mixin btn-secondary() {
	@include btn-base();
	background-color: var(--color-base);
	border: 1.5px solid var(--color-primary-dark);
	border-radius: var(--br-sm);
	color: var(--color-primary-dark);
	padding: calc(var(--spacing-base) * 2) calc(var(--spacing-base) * 3);

	&::after {
		background-color: var(--color-primary-dark);
	}

	&:hover {
		box-shadow: var(--btn-shadow);
	}

	&:active,
	&:focus,
	&:focus-visible {
		background-color: var(--color-active-secondary);
		border-color: var(--color-active-primary);
		color: var(--color-active-primary);
		box-shadow: none;

		&::after {
			background-color: var(--color-active-primary);
		}
	}
}

@mixin btn-tertiary() {
	@include btn-base();
	color: var(--color-primary-dark);
	gap: 12px;

	&::after {
		background-color: var(--color-primary-dark);
		position: relative;
		right: 4px;
	}

	&:hover {
		&::after {
			right: 0;
			transition: all 300ms ease-out;
		}
	}

	&:active,
	&:focus,
	&:focus-visible {
		color: var(--color-active-primary);

		&::after {
			background-color: var(--color-active-primary);
		}
	}
}
