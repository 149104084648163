.innovation-button {
	background-color: $color-green-lightest;
	border: 1px solid $color-green-lightest;
	border-radius: 20px;
	color: $color-neutral-darker;
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-regular;
	padding: 8px 25px;
	text-align: center;

	@include text(theta, lambda);

	&.cancel {
		background-color: $color-white;
		border: 1px solid #707070;
	}

	&.hero-primary {
		background-color: $color-green-dark;
		border: 1px solid $color-green-dark;
		color: $color-white;
		padding: 4px 16px;
	}

	&.hero-secondary {
		background-color: transparent;
		border: 1px solid $color-green-darker-2;
		color: $color-green-darker-2;
		padding: 4px 16px;
	}

	&:hover {
		background-color: $color-blue-lightest-2;
		border: 1px solid $color-blue-lightest-2;
		cursor: pointer;

		&.hero-primary {
			color: $color-black;
		}

		&.hero-secondary {
			border: 1px solid $color-blue-lightest-2;
			color: $color-black;
		}
	}

	@include breakpoint(md) {
		&.hero-primary,
		&.hero-secondary {
			padding: 4px 70px;
		}
	}
}

.green-btn {
	@include text(theta);
	background-color: $color-green-dark;
	border: 2px solid $color-green-dark;
	border-radius: 10px;
	color: $color-white;
	cursor: pointer;
	display: flex;
	font-weight: $font-weight-medium;
	justify-content: center;
	padding: 6px;
	width: 100%;

	@include breakpoint(lg) {
		font-size: 25px;
		line-height: 30px;
		padding: 14px 32px;
		width: fit-content;
	}
}

a.green-btn {
	@include text(theta);

	@include breakpoint(lg) {
		font-size: 25px;
		line-height: 30px;
	}
}

.green-btn-inverse {
	@include text(theta, theta);
	border: 2px solid $color-green-dark;
	border-radius: 10px;
	color: $color-green-dark;
	cursor: pointer;
	display: flex;
	font-weight: $font-weight-medium;
	justify-content: center;
	padding: 6px;
	width: 100%;

	@include breakpoint(lg) {
		padding: 14px 32px;
		width: fit-content;
	}
}

.lg-full-width {
	@include breakpoint(lg) {
		width: 100%;
	}
}

.mobile {
	display: block;

	@include breakpoint(lg) {
		display: none;
	}
}

.desktop {
	display: none;

	@include breakpoint(lg) {
		display: block;
	}
}

//NEW OSF - Buttons
.overhauled {
	.btn-primary,
	.osf-green-cta {
		@include btn-primary();
	}

	.btn-secondary {
		@include btn-secondary();
	}

	.btn-tertiary {
		@include btn-tertiary();
	}

	.btn-mobile-fw {
		justify-content: center;
		text-align: center;
		width: 100%;

		@include breakpoint(md) {
			width: fit-content;
		}
	}
}

//Override for CHOI Button Arrow
[data-theme='choi'] {
	.overhauled {
		.btn-primary,
		.btn-secondary,
		.btn-tertiary {
			&::after {
				height: 16px;
				mask: url('/_next/images/icons/arrow-right.svg');
				mask-size: 18px 16px;
				min-width: 18px;
				width: 18px;
			}
		}
	}
}
